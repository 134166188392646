import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow,
  Tooltip,
  useToast
} from '@percent/lemonade'
import { getFormattedDate } from '@percent/partner-dashboard/common/library/utility/date'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { VolunteeringSessionsTableProps } from './VolunteeringSessionsTable.types'

import styles from '../../VolunteeringTable/VolunteeringTable.module.scss'
import { VolunteeringActionsMenu } from '../../VolunteeringActionsMenu/VolunteeringActionsMenu'
import { CancelOpportunityModal } from '../../CancelOpportunity/Modal/CancelOpportunityModal'
import { CancelOpportunityErrorModal } from '../../CancelOpportunity/ErrorModal/CancelOpportunityErrorModal'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { CancelOpportunityErrorCode } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import { CancelOpportunitySuccessModal } from '../../CancelOpportunity/SuccessModal/CancelOpportunitySuccessModal'

export function VolunteeringSessionsTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  refresh
}: Readonly<VolunteeringSessionsTableProps>) {
  const { t } = useTranslation()
  const history = useHistory()
  const { workplaceGivingService } = useServices()

  const [selectedOpportunity, setSelectedOpportunity] = useState<{ id: string; name: string } | undefined>()
  const [cancelErrorModalOpen, setCancelErrorModalOpen] = useState(false)
  const [cancelSuccessModalOpen, setCancelSuccessModalOpen] = useState(false)

  const [{ isLoading: isCancelLoading, error }, { apiFunc: cancelOpportunity }] = useMutation(
    workplaceGivingService.cancelVolunteeringOpportunity,
    () => {
      setCancelSuccessModalOpen(true)
      refresh()
    },
    error => {
      if (error) {
        setCancelErrorModalOpen(true)
      }
    }
  )

  const handleCancelOpportunity = () => {
    if (selectedOpportunity?.id) {
      cancelOpportunity(selectedOpportunity.id)
      setSelectedOpportunity(undefined)
    }
  }

  const columns = useMemo(
    () => [
      { id: t('table.header.name'), props: { width: '65%' } },
      { id: t('table.header.attendees'), props: { width: '13%' } },
      { id: t('table.header.createdAt'), props: { width: '17%' } },
      { id: t('table.header.actions'), props: { width: '5%' } }
    ],
    [t]
  )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <TableProvider
        columns={columns}
        totalResults={totalResults}
        isLoading={isLoading || !data}
        illustration="no-results"
        emptyTableTitle={t('typography.noVolunteeringOpportunitiesSessionsTitle')}
        emptyTableDescription={t('typography.noVolunteeringOpportunitiesSessionsDescription')}
      >
        <Table>
          <TableBody>
            {data?.map(opportunity => {
              const opportunityHref = `${process.env.NX_WPG_ADDRESS}/volunteer/opportunities/${opportunity.id}`
              const OpportunityLink = (
                <a className={styles.link} href={opportunityHref} target="_blank" rel="noopener noreferrer">
                  {opportunity.name.length > 38 ? `${opportunity.name.substring(0, 35)}...` : opportunity.name}
                </a>
              )

              return (
                <TableRow key={opportunity.id}>
                  <TableCell>
                    {opportunity.name.length > 38 ? (
                      <Tooltip content={opportunity.name}>{OpportunityLink}</Tooltip>
                    ) : (
                      OpportunityLink
                    )}
                  </TableCell>
                  <TableCell>
                    {`${opportunity.participants.count}${
                      opportunity.participants.spots ? `/${opportunity.participants.spots}` : ''
                    }`}
                  </TableCell>
                  <TableCell>{getFormattedDate(opportunity.createdAt)}</TableCell>
                  <TableCell>
                    <VolunteeringActionsMenu
                      items={[
                        {
                          key: 'view',
                          label: t('button.view')
                        },
                        {
                          key: 'cancel',
                          label: t('button.cancel')
                        }
                      ]}
                      handleSelect={(actionKey: string) => {
                        switch (actionKey) {
                          case 'view':
                            window.open(opportunityHref, '_blank', 'noopener noreferrer')
                            break
                          case 'seeSessions':
                            history.push(`/workplace-giving/volunteering/${opportunity.id}`)
                            break
                          case 'cancel':
                            setSelectedOpportunity({
                              id: opportunity.id,
                              name: opportunity.name
                            })
                            break
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </TableProvider>
      {selectedOpportunity ? (
        <CancelOpportunityModal
          open={!!selectedOpportunity}
          opportunityName={selectedOpportunity.name}
          handleCancelOpportunity={handleCancelOpportunity}
          handleClose={() => {
            setSelectedOpportunity(undefined)
          }}
          isLoading={isCancelLoading}
        />
      ) : null}
      <CancelOpportunitySuccessModal
        open={cancelSuccessModalOpen}
        handleClose={() => {
          setCancelSuccessModalOpen(false)
        }}
      />
      {error ? (
        <CancelOpportunityErrorModal
          open={cancelErrorModalOpen}
          handleClose={() => {
            setCancelErrorModalOpen(false)
          }}
          errorCode={error.code as CancelOpportunityErrorCode}
        />
      ) : null}
    </>
  )
}
