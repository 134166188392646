import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, Modal } from '@percent/lemonade'
import { CancelOpportunityErrorModalProps } from '../ErrorModal/CancelOpportunityErrorModal.types'

export function CancelOpportunitySuccessModal({
  open,
  handleClose
}: Readonly<Omit<CancelOpportunityErrorModalProps, 'errorCode'>>) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="cancel-opportunity-success">
      <AcknowledgeModal
        result="positive"
        title={t('dialog.volunteering.cancel.success.title')}
        buttonText={t('button.close')}
        handleClose={handleClose}
        buttonTestId="success-modal-btn"
      />
    </Modal>
  )
}
