import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, ActionModal, Modal } from '@percent/lemonade'
import { CloseFundraiserModalProps } from './CloseFundraiserModal.types'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useState } from 'react'

export function CloseFundriaserModal({ isOpen, onClose, refresh, fundraiser }: Readonly<CloseFundraiserModalProps>) {
  const { t } = useTranslation()
  const { fundraisersService } = useServices()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const [{ isLoading, data }, { apiFunc: closeFundraiser }] = useMutation(
    fundraisersService.closeFundraiser,
    () => {
      setIsSuccess(true)
      refresh()
    },
    () => {
      setIsError(true)
    }
  )

  const handleCloseFundraiser = () => {
    closeFundraiser(fundraiser.id)
  }

  const handleCloseSuccessModal = () => {
    setIsSuccess(false)
    onClose()
  }

  const handleCloseErrorModal = () => {
    setIsError(false)
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} aria-labelledby="open-close-fundraiser">
        <ActionModal
          title={t('dialog.fundraisers.close.title')}
          primaryButtonText={t('typography.yesClose')}
          secondaryButtonText={t('typography.keepFundraiser')}
          handleClose={handleClose}
          handleSubmit={handleCloseFundraiser}
          loading={!!isLoading}
          primaryBtnTestId="btn-close-fundraiser"
          disabled={!!isLoading}
        >
          {t('dialog.fundraisers.close.description', {
            fundraiserName: fundraiser.page.title
          })}
        </ActionModal>
      </Modal>
      {data && (
        <Modal open={isSuccess} onClose={handleCloseSuccessModal} aria-labelledby="close-fundraiser-success">
          <AcknowledgeModal
            result="positive"
            title={t('dialog.fundraisers.close.success.title')}
            description={t('dialog.fundraisers.close.success.description')}
            buttonText={t('button.close')}
            handleClose={handleCloseSuccessModal}
            buttonTestId="success-modal-btn"
          />
        </Modal>
      )}
      <Modal open={isError} onClose={handleCloseErrorModal} aria-labelledby="close-fundraiser-error">
        <AcknowledgeModal
          result="negative"
          title={t('dialog.fundraisers.close.error.title')}
          description={t('dialog.fundraisers.close.error.description')}
          buttonText={t('button.close')}
          handleClose={handleCloseErrorModal}
          buttonTestId="error-modal-btn"
        />
      </Modal>
    </>
  )
}
