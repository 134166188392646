import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'
import { VolunteeringTable } from './VolunteeringTable/VolunteeringTable'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbsData } from '../../layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { useEffect } from 'react'
import { OpportunityStatus } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'

const initialFilters = {
  parentId: 'null',
  status: [OpportunityStatus.ACTIVE, OpportunityStatus.CANCELLED, OpportunityStatus.CLOSED]
}

export function VolunteeringContainer() {
  const { workplaceGivingService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams(
    {
      query: StringParam,
      parentId: StringParam,
      status: ArrayParam
    },
    {}
  )

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    workplaceGivingService.getVolunteeringOpportunitiesList,
    {
      ...queryParams,
      ...initialFilters
    }
  )

  useDidMountEffect(() => {
    if (!queryParams.parentId && !queryParams.status) {
      query({ ...queryParams, parentId: 'null' })
      setQueryParams?.({
        ...queryParams,
        ...initialFilters
      })
    } else {
      query({ ...queryParams, parentId: 'null' })
    }
  }, [queryParams, query])

  useDidMountEffect(() => {
    setQueryParams?.({
      ...queryParams,
      ...initialFilters
    })
  }, [])

  const { t } = useTranslation()
  const { setPath } = useBreadcrumbsData()

  useEffect(() => {
    setPath(t('menu.workplaceGivingSubmenu.volunteering'))
  }, [t, setPath])

  return (
    <VolunteeringTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
